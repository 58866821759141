<template>
        <div>
        <div class="row">
            <div class="col-md-9">
                <div class="card shadow " style="margin-top:20px;">
                    <div class="card-header card-header-background">
                        <h2 class="d-none d-sm-block"><i class="fal fa-fw fa-euro-sign"></i> Conferma il tuo Ordine</h2>
                        <h2 class="d-none d-block d-sm-none"><i class="fal fa-fw fa-euro-sign"></i> Conferma</h2>
                    </div>
                    <div class="card-body" style="padding:0">
                        <div class="col-md-12" style="margin-top:20px">
                            <div class="row">
                                <div class="col-md-4 confirm-title" style="font-weight:bold;">
                                    <i class="fal fa-envelope fa-fw"></i> INFORMAZIONI DI CONTATTO
                                </div>
                                <div class="col-md-8" style="font-weight:normal">
                                    Email: {{ordine.EMAIL}}<br/>
                                    Telefono : {{ordine.TELEFONO}}
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-md-4 confirm-title" style="font-weight:bold;">
                                    <i class="fal fa-credit-card fa-fw"></i> PAGAMENTO E ALTRE OPZIONI
                                </div>
                                <div class="col-md-8" style="font-weight:normal">
                                    Modalità di pagamento: {{ordine.FK_ID_MODALITA_PAGAMENTO == 1 ? 'Pagamento con carta di Credito' : 'Pagamento in contanti alla consegna'}}<br/>
                                    NOTE : {{ordine.NOTE}}
                                </div>
                            </div>
                            <hr/>
                            <div class="row" style="margin-bottom:20px">
                                <div class="col-md-4 confirm-title" style="font-weight:bold;">
                                    <i class="fal fa-check fa-fw"></i> TOTALE
                                </div>
                                <div class="col-md-8" style="font-weight:normal">
                                    Carrello: € {{filters.formattaImporto(ordine.TOTALE_NETTO)}}<br/>
                                    Spedizione / consegna: € {{filters.formattaImporto(ordine.SPESE_CONSEGNA)}}<br/>
                                    <hr>
                                    <strong>Totale Ordine: € {{filters.formattaImporto(ordine.TOTALE_FINITO)}}</strong> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <Carrello :modalita="2"></Carrello>
            </div>
            <button class="btn btn-success btn btn-block btn-lg" v-on:click="generaOrdine()" style="margin-bottom:20px;margin-top:20px"><i class="fal fa-check fa-fw"></i>Genera ordine</button>
        </div>
    </div>
</template>

<script>

import Carrello from '@/components/common/Carrello.vue'


export default {
    name:"Confirm",
    components:{
        Carrello
    },
    props:[],
    data:function(){
        return{
            token:"",
            ordine:{},
            filters:global.filters,
        }
    },
    methods: {
        generaOrdine(){
            utils.ajax('/deskalo/checkout/ordine/insert',{token:this.token}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.$root.ordine = {ID_ORDINE:response.id_inserito};
                    this.$root.ordine_redirect_success = 1;
                    if (this.ordine.FK_ID_MODALITA_PAGAMENTO == 1){
                        global.router.push("Stripe");
                    }
                    else{
                        //this.$root.changeRoute('ordine');
                    }
                }
            });
        }
    },
    computed: {
    
    },
    created : function(){
        this.token = this.$root.token_ordine
        utils.ajax('ec/checkout/token',{token:this.token}, (response) => {
            if (response.esito == 0){
                this.ordine = response.data;
            }
        });
    },
}
</script>