<template>
    <div>
        <div class="card shadow " style="margin-top:20px;">
            <div class="card-header card-header-background">
                <h2 v-if="this.modalita != 3"><i class="fal fa-shopping-cart"></i> Il tuo Carrello</h2>
                <h2 v-if="this.modalita == 3"><i class="fa-fw fal fa-list"></i> Elenco quote</h2>
            </div>
            <div class="card-body" style="padding:0">
                <div>
                    <div class="font-weight-bold mb-1" style="font-size:14px;color:#6c6c6c;min-height:300px">
                        <ul class="list-group" style="margin-top: -2px;">
                            <li class="list-group-item d-flex align-items-center" v-for="item in dati.ITEMS" v-bind:key="item.CHIAVE" style="border-radius:0;padding-top: 10px;padding-bottom: 10px;padding-left: 0px;padding-right: 0px;">
                                <div class="row" style="margin-right:0px;margin-left:0px;width:100%">
                                    <div v-bind:class="{'col-5':modalita == 1,'col-7':modalita == 2,'col-9':modalita == 3}" >
                                        <div>{{item.DESCRIZIONE}}</div>
                                    </div>
                                    <div v-bind:class="{'col-3':modalita == 1,'col-2':modalita == 2}"  class="d-flex align-items-center" style="height:100%" v-if="modalita != 3">
                                        <select v-if="modalita == 1 && noq != 1" @change="modificaQuantita(item)" class="form-control form-control-sm" v-model="item.QUANTITA" >
                                            <option v-for="index in 24" v-bind:value="index" v-bind:key="index">{{index}}</option>
                                        </select>
                                        <span class="badge badge-warning" style="font-size:16px" v-if="modalita == 2">x {{item.QUANTITA}}</span>
                                    </div>
                                    <div class="col-3 d-flex align-items-center" style="height:100%">
                                        € {{filters.formattaImporto(item.PREZZO_TOTALE)}}
                                    </div>
                                    <div class="col-1 d-flex align-items-center" style="height:100%"  v-if="modalita == 1">
                                        <button v-on:click="deleteProdotto(item)"  type="button" class="close" style="margin-top:-4px" aria-label="Close">x</button>
                                    </div>                                     

                                    <div class="col-md-12" style="padding:0px">
                                        <div class="col-12" v-for="variante in getOpzioniSelezionateItem(item)" v-bind:key="variante" style="border-bottom:1px solid #f8f8f8">
                                            <small> + {{variante.DESCRIZIONE}} ({{filters.formattaImporto(variante.PREZZO_UNITARIO * item.QUANTITA)}})<button v-if="modalita == 1" v-on:click="rimuoviVariante(item,variante)" type="button" aria-label="Close" class="close" style="margin-top: -4px;">x</button></small>
                                        </div>
                                        <div class="col-12" v-if="item.FK_ID_TIPO == 1 && item.VARIANTI.length != null && item.VARIANTI.length > 0 && modalita == 1" >
                                            <button class="btn btn-sm btn-info" style="padding:0.01rem 0.20rem" v-on:click="opzioniClickHandler(item)"><i v-if="item.opzioniOpen != 1" class="fal fa-plus fa-fw"></i><i v-else class="fal fa-minus fa-fw"></i>Opzioni</button>
                                        </div>
                                        <div class="col-12" style="margin-top: 10px;border-top: 1px solid #f2f3f6;padding-top: 10px;" v-show="item.hasOwnProperty('opzioniOpen') && item.opzioniOpen == 1">
                                            <div v-show="item.VARIANTI != null" v-for="opzione in item.VARIANTI" v-bind:key="opzione">
                                                <label class="checkbox-label-sm"><input class="checkbox-sm" type="checkbox"  v-model="opzione.SELEZIONATO" @change="gestioneOpzione(item,opzione)"> {{opzione.DESCRIZIONE}} (+{{ filters.formattaImporto(opzione.PREZZO_UNITARIO * item.QUANTITA)}})</label>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </li>
                        </ul>
                    </div>  
                </div>
            </div>
            <div class="card-footer" style="font-weight:bold">
                <span style="font-size:18px">Totale</span>
                <span style="font-size:18px" class="float-right">€ {{filters.formattaImporto(dati.TOTALE)}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"Carrello",
    props:['modalita','noq'],
    data:function(){
        return{
            filters:global.filters,
            dati:{}
        }
    },
    methods: {
        setCarrello : function(dati){
            this.dati = dati;
           // this.$root.$refs.hcarrello.setCarrello(dati);
          //  this.$root.$refs.scarrello.setCarrello(dati);
        },
        setCarrelloOrdine : function(dati,totale){
            this.dati.ITEMS = dati;
            this.dati.TOTALE = totale;
            this.$forceUpdate();
        },
        modificaQuantita : function(prodotto){
            utils.ajax('/ec/carrello/prodotto/edit/quantita',{ID_PRODOTTO:prodotto.ID_PRODOTTO,QUANTITA:prodotto.QUANTITA,CHIAVE:prodotto.CHIAVE}, (response) => {
                if (response.esito == 0){
                    this.setCarrello(response.data);                    
                }
            });
        },
        deleteProdotto : function(prodotto){
            utils.ajax('/ec/carrello/prodotto/remove',{ID_PRODOTTO:prodotto.ID_PRODOTTO,CHIAVE:prodotto.CHIAVE}, (response) => {
                if (response.esito == 0){
                    this.setCarrello(response.data);                    
                }
            });
        },
        opzioniClickHandler : function(item){
            item.opzioniOpen = item.opzioniOpen == 1 ? 0 : 1;
            this.$forceUpdate();
        },
        gestioneOpzione : function(item,variant){
            var api = variant.SELEZIONATO == 1 ? '/ec/carrello/prodotto/variante/add' : '/ec/carrello/prodotto/variante/remove';
            utils.ajax(api,{ID_PRODOTTO:item.ID_PRODOTTO,FK_ID_PRODOTTO:variant.ID_PRODOTTO,CHIAVE:item.CHIAVE}, (response) => {
                this.setCarrello(response.data);
                for (var i = 0 ; i < this.dati.ITEMS.length ; i++){
                    if (this.dati.ITEMS[i].CHIAVE == item.CHIAVE ){
                        this.dati.ITEMS[i].opzioniOpen = 1;
                    }
                }
            });
        },
        rimuoviVariante : function(item,variant){
            variant.SELEZIONATO = 0;
            this.gestioneOpzione(item,variant);
        },
        getOpzioniSelezionateItem : function(item){
            if (this.modalita != 3){
                if (item.VARIANTI == null){
                    return new Array();
                }
                return utils.array.select(item.VARIANTI,"SELEZIONATO",1);
            }
            else{
                return item.VARIANTI;
            }
        }
    },
    computed: {
    
    },
    created : function(){
        if (this.modalita != 3){
            utils.ajax('/ec/carrello',{}, (response) => {
                this.setCarrello(response.data);
            });
        }
    },
}
</script>