import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'jquery';
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import "./assets/css/sb-admin-2.css"
import "./assets/css/custom.css"
import "./assets/css/personalizzazioni.css"
import "./assets/css/css-loader.css"
import "./assets/css/loader-default.css"
import "./assets/css/bootstrap-datepicker3.standalone.min.css"
import "./assets/css/notify.min.css"
import "./assets/css/select2.min.css"
import "./assets/css/select2-bootstrap4.min.css"
import "./assets/css/FA_ORIG/css/all.min.css"
import 'popper.js';
import 'bootstrap';
import 'summernote';
import 'summernote/dist/summernote-lite.min.css';
import "./assets/js/select2.min.js"
import * as echarts from 'echarts/dist/echarts.js';
import utils from "./assets/js/utils.js"
import filters from "./assets/js/filters.js"
import moment from 'moment';
import "bootstrap-datepicker";
//import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";



global.router = router;
global.echarts = echarts;
global.utils = utils.utils;
global.filters = filters.filters;
global.moment = moment;

global.sprint = createApp(App).use(router)
global.sprint.config.devtools = true
global.sprint.mount('#app');


$(function(){
    //LINGUA DELLA DATEPICKER
    !function(a){a.fn.datepicker.dates.it={days:["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"],daysShort:["Dom","Lun","Mar","Mer","Gio","Ven","Sab"],daysMin:["Do","Lu","Ma","Me","Gi","Ve","Sa"],months:["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"],monthsShort:["Gen","Feb","Mar","Apr","Mag","Giu","Lug","Ago","Set","Ott","Nov","Dic"],today:"Oggi",monthsTitle:"Mesi",clear:"Cancella",weekStart:1,format:"dd/mm/yyyy"}}(global.jQuery);
    $.fn.datepicker.defaults.language = 'it';
  
    (function($) {
      "use strict"; // Start of use strict
      // Toggle the side navigation
      $("#sidebarToggle, #sidebarToggleTop").on('click', function() {
        
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
          $('.sidebar .collapse').collapse('hide');
        }
      });
    
      // Close any open menu accordions when window is resized below 768px
      $(window).resize(function() {
        if ($(window).width() < 768) {
          $('.sidebar .collapse').collapse('hide');
        }
      });
    
      // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
        if ($(window).width() > 768) {
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      });
    
      // Scroll to top button appear
      $(document).on('scroll', function() {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });
    
      // Smooth scrolling using jQuery easing
      $(document).on('click', 'a.scroll-to-top', function(e) {
        var $anchor = $(this);
        $('html, body').stop().animate({
          scrollTop: ($($anchor.attr('href')).offset().top)
        }, 1000, 'easeInOutExpo');
        e.preventDefault();
      });
    
    })(global.jQuery); // End of use strict
});

global.utils.createLoader();

$(function(){
    //$('html').css("overflow-y","hidden");
    global.utils.resizeContent();
    $(window).resize(function(){
        global.utils.resizeContent();
    });
    global.$('html').click(function(e) {
     // console.log("html");
      //if clicked element is not your element and parents aren't your div
      if (global.utils.navIsOpen == true && e.target.id != 'mySidenav' && e.target.className != 'sidenav' && e.target.className!= 'select2-selection__choice__remove'  &&  $(e.target).parents('.sidenav').length == 0) {
         // console.log("closeNav");
          global.utils.closeNav();
      }
  });
});