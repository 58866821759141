import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Shop from '../views/Shop.vue'
import Checkout from '../views/Checkout.vue'
import Confirm from '../views/Confirm.vue'
import Stripe from '../components/common/Stripe.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {path: '/login',  name: 'Login',    component: Login},
  {path: '/dashboard',  name: 'Dashboard',    component: Dashboard},
  {path: '/shop',  name: 'Shop',    component: Shop},
  {path: '/checkout',  name: 'Checkout',    component: Checkout},
  {path: '/confirm',  name: 'Confirm',    component: Confirm},
  {path: '/stripe',  name: 'Stripe',    component: Stripe},

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
