<template>
    <div>
        <div class="row" style="margin-bottom:20px">
            <div class="col-md-9" style="padding:0px">
                <div class="col-md-12" style="padding:0">
                    <div class="card shadow " style="margin-top:20px;">
                        <div class="card-header card-header-background">
                            <h2><i class="fa-fw fal fa-store-alt"></i> Società</h2>
                        </div>
                        <div class="card-body" style="padding:0">
                            <div class="row" style="padding:10px">
                                <div style="float:left;margin-left:20px">
                                    <img :src="'https://assosprint.it/app/IMG/LOGHI/'+ negozio.URL_LOGO" style="max-height:140px;" class="img-responsive shadow" alt="logo negozio">
                                </div>
                                <div style="float:left;margin-left:20px">
                                    <div style="margin-top:0px" v-if="negozio.ID_NEGOZIO > 0">
                                        <div class="font-weight-bold  mb-1" style="font-size:24px">
                                            {{negozio.ALIAS}}
                                        </div>  
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-map-marker-alt fa-fw"></i> {{negozio.INDIRIZZO}} - {{negozio.comune.NOME}}
                                        </div> 
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-phone fa-fw"></i>{{negozio.TELEFONO}}
                                        </div> 
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-credit-card fa-fw"></i>{{negozio.ACCETTA_PAGAMENTI_ONLINE == 1 ? 'Accetta' : 'NON accetta'}} pagamenti online
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card shadow " style="margin-top:10px;">
                            <div class="card-header card-header-background">
                                <h2><i  class="fa-fw fal fa-euro-sign "></i>Prodotti
                                    <div class="float-right">
                                        <form class="isprint-filter" autocomplete="off" style="height:38px;margin-bottom:0px">            
                                            <input type="text" class="form-control" v-model="search.DESCRIZIONE.VALUE" placeholder="Ricerca" autocomplete="off">
                                        </form>
                                    </div>
                                </h2>
                            </div>
                            <div class="card-body" style="padding:0">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="prodotto in paginatedList.arr" v-bind:key="prodotto.ID_PRODOTTO" v-show="prodotto.FK_ID_TIPO == 1">
                                        <div class="col-8">
                                            <strong>{{prodotto.DESCRIZIONE}}</strong><br/><span style="font-size:16px">€ {{prodotto.PREZZO_UNITARIO}}</span>
                                        </div>
                                        <div class="col-4">
                                            <button v-on:click="addToCart(prodotto)" class="btn btn-warning float-right" ><i class="fal fa-cart-plus"></i>Aggiungi</button>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>
                    <div class="col-md-6">
                        <div class="card shadow " style="margin-top:10px;">
                            <div class="card-header card-header-background">
                                <h2><i  class="fa-fw fal fa-calendar-alt  "></i>Attività prenotabili
                                </h2>
                            </div>
                            <div class="card-body" style="padding:0">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="attivita in arrayAttivita" v-bind:key="attivita.ID_ATTIVITA">
                                        <div class="col-8">
                                            <strong>{{attivita.DESCRIZIONE}}</strong><br/><span style="font-size:16px">{{attivita.NOME}} {{attivita.COGNOME}} <br/><small>{{filters.formatDate(attivita.DATA) }} | {{getLblOra(attivita.ORA_INIZIO)}} - {{getLblOra(attivita.ORA_FINE)}}</small></span>
                                        </div>
                                        <div class="col-4">
                                            <button v-on:click="prenotaAttivita(attivita)" class="btn btn-primary float-right" ><i class="fal fa-check"></i>Prenota</button>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>                
                </div>

            </div>
            <div class="col-md-3">
                <Carrello :ref="'carrello'" :modalita="1" :noq="1"></Carrello>
                <button class="btn btn-warning btn btn-lg btn-block" style="margin-top:10px" v-on:click="checkOut()"><i class="fal fa-fw fa-euro-sign"></i>Checkout</button>
            </div>
        </div>

        <div class="modal fade" id="popUpSoci"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Seleziona socio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item" style="cursor:pointer" v-for="socio in arraySoci" v-on:click="impostaSocio(socio)" v-bind:key="socio.ID_SOCIO"><i class="fal fa-user-alt"></i>{{socio.COGNOME}} {{socio.NOME}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpSocioQuotaPeriodica"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Seleziona socio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <h4>Seleziona i mesi per i quali stai effettuando il pagamento</h4>
                        <h5>Socio: {{socioSelezionato.COGNOME}} {{socioSelezionato.NOME}}</h5>
                        <h5>Quota: {{prodottoSelezionato.DESCRIZIONE}}</h5>
                        <hr/>
                        <div class="row">
                            <div class="col-md-4" v-for="mese in arrayMesi" style="margin-bottom:10px" v-on:click="selezionaMese(mese)" v-bind:key="mese">
                                <div style="border: 1px solid #ccc;padding: 10px;border-radius: 6px;text-align: center;" class="lift-panel" v-bind:style="getStileMese(mese)">
                                    {{mese.MESE}}/{{mese.ANNO}}                            
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-success btn-lg btn-block" v-on:click="addQuotaPeriodica()">Conferma</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpSocioScadenzario"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Seleziona socio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <h4>Seleziona la rata di cui intendi effettuare il pagamento</h4>
                        <h5>Socio: {{socioSelezionato.COGNOME}} {{socioSelezionato.NOME}}</h5>
                        <h5>Quota: {{prodottoSelezionato.DESCRIZIONE}}</h5>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12" v-for="rata in arrayRateScadenzario" style="margin-bottom:10px" v-bind:key="rata.ID_RATA" v-on:click="selezionaRata(rata)">
                                <div style="border: 1px solid #ccc;padding: 10px;border-radius: 6px;text-align: center;" class="lift-panel" v-bind:style="getStileRata(rata)">
                                    {{rata.DESCRIZIONE}} - € {{filters.formattaImporto(rata.IMPORTO)}} - Scadenza {{filters.formatDate(rata.DATA_SCADENZA)}}
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-success btn-lg btn-block" v-on:click="addQuotaScadenzario()">Conferma</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Carrello from '@/components/common/Carrello.vue'


export default {
    name:"Shop",
    components:{
        Carrello
    },
    data:function(){
        return{
            filters:global.filters,
            arrayProdotti : new Array(),
            negozio : {},
            arraySoci : new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:200},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            prodottoSelezionato:{},
            socioSelezionato : {},
            prodottoAsSelezionato : {},
            arrayMesi : new Array(),
            arrayMesiSelezionati : new Array(),
            arrayRateScadenzario : new Array(),
            rataScadenzarioSelezionata : {},
            arrayAttivita : new Array()
        }
    },
    methods: {
        getProdotti : function(){
            utils.ajax('ec/negozio/prodotti',{ID_NEGOZIO:this.negozio.ID_NEGOZIO}, (response) => {
                this.arrayProdotti = response.data;
            });
        },
        getImmagineSfondo : function(prodotto){
            var url = 'CORE/UPLOAD/PRODOTTI/'+ prodotto.URL_IMMAGINE;
            return {
                "background-image": "url('"+ url +"')",
                "background-position":"center",
                "background-repeat":"no-repeat",
                "background-size":"cover",    
            }
        },
        addToCart : function(prodotto){
            this.prodottoSelezionato = utils.clone(prodotto);
            if (this.arraySoci.length == 1){
                this.impostaSocio(this.arraySoci[0]);
            }
            else{
                var modal = $('#popUpSoci');
                modal.modal('show');
            }
        },
        impostaSocio : function(socio){
            var modal = $('#popUpSoci');
            modal.modal('hide');
            this.socioSelezionato = utils.clone(socio);
            //VERIFICO IL TIPO DI PRODOTTO
            utils.ajax('/deskalo/prodotto/pre/add',{ID_PRODOTTO:this.prodottoSelezionato.ID_PRODOTTO,QUANTITA:1,FK_ID_SOCIO:this.socioSelezionato.ID_SOCIO}, (response) => {
                if (response.esito == 0){
                    if (response.data.ESITO == 0){
                        if (response.data.NEEDS_ACTION == 1){
                            this.prodottoAsSelezionato = response.data.PRODOTTO_AS;
                            //QUOTA PERIODICA
                            if (this.prodottoAsSelezionato.FK_ID_TIPO == 2){
                                this.arrayMesiSelezionati = new Array();
                                this.arrayMesi = response.data.ARRAY_MESI;
                                var modal = $('#popUpSocioQuotaPeriodica');
                                modal.modal('show');
                            }
                            else if (this.prodottoAsSelezionato.FK_ID_TIPO == 6){
                                this.rataScadenzarioSelezionata = {};
                                this.arrayRateScadenzario = response.data.RATE;
                                var modal = $('#popUpSocioScadenzario');
                                modal.modal('show');
                            }
                        }
                        else{
                            this.doAddToCart({});
                        }
                    }
                    else{
                        utils.alert.warning(response.data.MESSAGGIO);
                    }
                }
            });
        },
        addQuotaPeriodica : function(){
            var opzioni = {
                arrayMesi : this.arrayMesiSelezionati
            }
            var modal = $('#popUpSocioQuotaPeriodica');
            modal.modal('hide');
            this.doAddToCart(opzioni);
        },
        addQuotaScadenzario : function(){
            var opzioni = {
                rata : this.rataScadenzarioSelezionata
            }
            var modal = $('#popUpSocioScadenzario');
            modal.modal('hide');
            this.doAddToCart(opzioni);
        },
        doAddToCart : function(opzioni){
            opzioni.SOCIO = this.socioSelezionato;
            utils.ajax('/deskalo/carrello/prodotto/add',{ID_PRODOTTO:this.prodottoSelezionato.ID_PRODOTTO,QUANTITA:1,OPTIONS:opzioni}, (response) => {
                if (response.esito == 0){
                    this.$refs.carrello.setCarrello(response.data);
                }
            });
        },
        checkOut : function(){
            global.router.push("Checkout");
        },
        selezionaMese : function(mese){
            for (var i = 0 ; i < this.arrayMesiSelezionati.length ; i++){
                if (this.arrayMesiSelezionati[i].ANNO == mese.ANNO && this.arrayMesiSelezionati[i].MESE == mese.MESE){
                    this.arrayMesiSelezionati.splice(i,1);
                    return;
                }
            }
            this.arrayMesiSelezionati.push(mese);
        },
        selezionaRata : function(rata){
            if (this.rataScadenzarioSelezionata.ID_RATA == rata.ID_RATA){
                this.rataScadenzarioSelezionata = {};
            }
            else{
                this.rataScadenzarioSelezionata = utils.clone(rata);
            }
        },
        getStileMese : function(mese){
            for (var i = 0 ; i < this.arrayMesiSelezionati.length ; i++){
                if (this.arrayMesiSelezionati[i].ANNO == mese.ANNO && this.arrayMesiSelezionati[i].MESE == mese.MESE){
                    return {background:'#1cc88a'};
                }
            }
            if (this.arrayMesiSelezionati.length == this.prodottoAsSelezionato.VALORE_TIPO_PRODOTTO){
                return {background:'#eee'};
            }
            return {};
        },
        getStileRata : function(rata){
            if (rata.ID_RATA == this.rataScadenzarioSelezionata.ID_RATA){
                return {background:'#1cc88a'};
            }
            return {background:'#eee'};
        },
        getLblOra : function(oraInt){
            var s = "";
            var ora =  Math.floor(oraInt/12);
            var minuto = oraInt % 12 ;
            minuto = minuto*5;
            return (ora > 9 ? ora : "0" + ora.toString()) + ":" + (minuto > 9 ? minuto : "0" + minuto.toString());
        },
        prenotaAttivita : function(attivita){
            utils.alert.confirm("Sei sicuro di voler prenotare l'attività <strong>"+attivita.DESCRIZIONE + "<br/>DATA: "+ this.$options.filters.formatDate(attivita.DATA) +" - ORARIO: " + this.getLblOra(attivita.ORA_INIZIO)+ " - " + this.getLblOra(attivita.ORA_FINE) + "</strong><BR/>per il socio <strong>" + attivita.NOME + " " + attivita.COGNOME + "</strong>?",() => {
                utils.ajax('deskalo/attivita/prenota',{ID_CORSO : attivita.ID_CORSO,ID_SOCIO:attivita.ID_SOCIO,ID_DATA : attivita.ID_DATA,ORA_INIZIO : attivita.ORA_INIZIO,ORA_FINE:attivita.ORA_FINE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax('/deskalo/spesa/startup',{ID_NEGOZIO:this.negozio.ID_NEGOZIO}, (response) => {
                            this.arrayAttivita = response.data.ATTIVITA;
                        });
                    }
                });
            });
        }
    },
    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdotti,this.search);
            return arr;
        },
    },
    created : function() {
        var negozio = this.$root.negozioSelezionato;
        this.arraySoci = negozio.ARRAY_SOCI;
        utils.ajax('/deskalo/spesa/startup',{ID_NEGOZIO:negozio.ID_NEGOZIO}, (response) => {
            this.negozio = response.data.NEGOZIO;
            this.arrayAttivita = response.data.ATTIVITA;
            this.arrayProdotti = this.negozio.prodottiAbilitati;
        });
    },
}
</script>