<template>
    <div class="mt-4">
        <div class="col-md-12" style="padding:0px" >
            <div class="row" style="padding:0">
                <div class="col-md-12">
                    <div class="card shadow ">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-md-9">
                                    <!--
                                    <div style="float:left">
                                        <img style="width:90px;height:90px" class="img-profile rounded-circle " :src="'CORE/UPLOAD/AVATAR/'+ utente.URL_AVATAR" >
                                    </div>-->
                                    <div style="float:left;margin-left:20px">
                                        <div class="font-weight-bold text-ec h4">Benvenuto in Deskalo!<br/><small>Il sistema per gestire online le operazioni fatte al <strong>desk</strong></small></div>  
                                        <hr>
                                        <div class="font-weight-bold text-ec h5"><i class="fal fa-user fa-fw"></i>{{utente.persona.NOME}} {{utente.persona.COGOME}} | <i class="fal fa-envelope fa-fw"></i>{{utente.EMAIL}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div style="float:right" >
                                        
                                        <img style="max-width:260px" class="img-responsive" src="https://deskalo.it/HOSTED/ShopAssosprint/CLIENT/ASSETS/deskalo.png">
                                        
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3" v-if="home.IS_NEGOZIO == 1">
                            <div class="card shadow " style="margin-top:20px">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary text-uppercase mb-1">Ordini</div>  
                                            <div class="h6 mb-0  text-gray-800">Ci sono <strong>{{home.ORDINI_DA_APPROVARE}}</strong> ordini da approvare</div>                      
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-shopping-basket fa-4x"  style="color:#f6c23e"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                
                        </div>                
                        <div class="col-md-3"  v-if="home.IS_NEGOZIO == 1">
                            <div class="card shadow" style="margin-top:20px">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary text-uppercase mb-1">Prodotti</div>  
                                            <div class="h6 mb-0  text-gray-800">Hai <strong>{{home.COUNT_PRODOTTI}}</strong> prodotti</div>
                                            <div class="h6 mb-0  text-gray-800">Di cui <strong>{{home.COUNT_PRODOTTI_ABILITATI}}</strong> abilitati</div>                     
                                        </div>
                                        <div class="col-auto">
                                            <i class="fa-fw fal fa-pizza-slice fa-4x"  style="color:#597be1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                
                        </div>  
                        <div class="col-md-3"  v-if="home.IS_NEGOZIO == 1">
                            <div class="card shadow " style="margin-top:20px">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary text-uppercase mb-1">Incassi</div>  
                                            <div class="h6 mb-0  text-gray-800">Oggi: <strong>€ {{filters.formattaImporto(home.INCASSI_OGGI)}}</strong> - Ieri: € <strong>{{filters.formattaImporto(home.INCASSI_IERI)}}</strong></div> 
                                            <div class="h6 mb-0  text-gray-800">Settimana: € <strong>{{filters.formattaImporto(home.INCASSI_SETTIMANA)}}</strong></div>                                          
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-euro-sign fa-4x" style="color:#1cc88a"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>  
                        <div class="col-md-3">
                            <div class="card shadow lift-panel" style="margin-top:20px">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Carrello</div>  
                                            <div v-if="home.CARRELLO.ITEMS.length > 0" class="h6 mb-0  text-gray-800">Hai: {{home.CARRELLO.ITEMS.length }} prodotti nel carrello <button v-on:click="continuaShopping()" class="btn btn-warning btn-sm">Continua la spesa</button></div> 
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai prodotti nel carrello</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-shopping-cart fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>       
                        <div class="col-md-3">
                            <div class="card shadow lift-panel" style="margin-top:20px">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Prenotazioni</div>  
                                            <div v-if="home.PRENOTAZIONI.length > 0" class="h6 mb-0  text-gray-800">Hai: {{home.PRENOTAZIONI.length }} prenotazioni attive 
                                            <button v-on:click="mostraPrenotazioni()" class="btn btn-info btn-sm"><i class="fal fa-check-square fa-fw"></i>Mostra</button></div>
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai prenotazioni attive</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-calendar-alt fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>                      
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card shadow " style="margin-top:20px;">
                                <div class="card-header card-header-background">
                                    <h2><i class="fa-fw fal fa-university"></i> Elenco società 
                                        <div class="float-right">
                                            <form class="isprint-filter" autocomplete="off" style="height:38px;margin-bottom:0px">            
                                                <input type="text" class="form-control" v-model="search.ALIAS.VALUE" placeholder="Ricerca" autocomplete="off">
                                            </form>
                                        </div>
                                    </h2>
                                </div>
                                <div class="card-body" style="padding:0">
                                    
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3" v-for="negozio in paginatedList.arr" v-on:click="openNegozio(negozio)" v-bind:key="negozio.ID_NEGOZIO">
                            <div class="card shadow lift-panel " style="margin-top:20px;height:260px;">
                                <div class="card-body" style="text-align:center;padding:0">
                                    <div style="background:#2193f3;height:150px;color:#fff;text-align:left;font-weight:bold" v-bind:style="getImmagineSfondo(negozio)">
                                        
                                    </div>
                                    <div style="margin-top:10px">
                                        <div class="font-weight-bold mb-1" style="font-size:18px;color:#6c6c6c;height:55px" >{{negozio.ALIAS}}<br/><small><i class="fal fa-phone-rotary fa-fw"></i>{{negozio.TELEFONO}}</small></div>  
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>                
            </div>
        </div>
        <div class="modal fade" id="popUpPrenotazioni"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Prenotazioni attive</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item" style="cursor:pointer" v-for="attivita in home.PRENOTAZIONI" v-bind:key="attivita.ID_PRENOTAZIONE" >
                                <div class="col-10">
                                    <strong>{{attivita.DESCRIZIONE}}</strong><br/><span style="font-size:16px">{{attivita.NOME}} {{attivita.COGNOME}} <br/><small>{{filters.formatDate(attivita.DATA)}} | {{getLblOra(attivita.ORA_INIZIO)}} - {{getLblOra(attivita.ORA_FINE)}}</small></span>
                                </div>
                                <div class="col-2">
                                    <button v-on:click="annullaPrenotazione(attivita)" class="btn btn-danger float-right" ><i class="fal fa-trash-alt"></i></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"Dashboard",
    data:function(){
      return{
        filters:global.filters,
        utente:{persona:{}},
        startInterval:{},
        arrayNegozi:new Array(),
        home:{CARRELLO:{ITEMS:new Array()},PRENOTAZIONI:new Array()},
        search:{
          PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:200},
          ALIAS:{TYPE:'TEXT',VALUE:''},
        },
      }
    },
    props: ['todo'],
    methods:{
        getImmagineSfondo : function(negozio){
            var url = 'https://assosprint.it/app/IMG/LOGHI/'+ negozio.URL_LOGO;
            return {
            "background-image": "url('"+ url +"')",
            "background-position":"center",
            "background-repeat":"no-repeat",
            "background-size":"cover",    
            }
        },
        openNegozio : function(negozio){
            this.$root.negozioSelezionato = utils.clone(negozio);
            global.router.push("Shop");
        },
        continuaShopping : function(){
            this.openNegozio({ID_NEGOZIO:this.home.CARRELLO.ID_NEGOZIO});
        },
        testEmail : function(){
            utils.ajax('/as/ricevuta/invia',{}, (response) => {
            
            });
        },
        getLblOra : function(oraInt){
            var s = "";
            var ora =  Math.floor(oraInt/12);
            var minuto = oraInt % 12 ;
            minuto = minuto*5;
            return (ora > 9 ? ora : "0" + ora.toString()) + ":" + (minuto > 9 ? minuto : "0" + minuto.toString());
        },
        mostraPrenotazioni : function(){
            var modal = $('#popUpPrenotazioni');
            modal.modal('show');
        },
        annullaPrenotazione : function(attivita){
            var modal = $('#popUpPrenotazioni');
            modal.modal('hide');

            utils.alert.confirm("Sei sicuro di voler annullare la prenotazione dell'attività <strong>"+attivita.DESCRIZIONE + "<br/>DATA: "+ this.$options.filters.formatDate(attivita.DATA) +" - ORARIO: " + this.getLblOra(attivita.ORA_INIZIO)+ " - " + this.getLblOra(attivita.ORA_FINE) + "</strong><BR/>per il socio <strong>" + attivita.NOME + " " + attivita.COGNOME + "</strong>?",() => {
            utils.ajax('/as/attivita/annulla',{ID_PRENOTAZIONE:attivita.ID_PRENOTAZIONE}, (response) => {
                if (response.esito == 0){
                utils.ajax('core/home',{}, (response) => {
                    this.home = response.data;
                    this.arrayNegozi = response.data.NEGOZI_ASSOSPRINT;
                    utils.alert.success(response.messaggio);
                });
                }
            });
            });
        }
    },
    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.arrayNegozi,this.search);
            return arr;
        },
    },
    created: function () {
        utils.ajax('core/home',{}, (response) => {
            this.utente = response.data.UTENTE;
            this.home = response.data;
            this.arrayNegozi = response.data.NEGOZI_ASSOSPRINT;
        });
    },
}
</script>