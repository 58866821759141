<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark shadow static-top headerbar " style="min-height: 69px;padding:0px; box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.45) !important;z-index:999">
      <router-link tag="a" class="navbar-brand" href="#" :to="'Dashboard'" style="padding-left:6px">
        <img src="./assets/img/logo_bianco.png" class="img img-responsive" style="margin-top:0px;max-height:43px">
      </router-link>
      <button class="navbar-toggler mr-2" type="button" data-toggle="collapse" id="btnCollapseMenu" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="topbar-divider d-none d-md-block " ></div>
      <div class="collapse navbar-collapse " id="navbarSupportedContent" style="z-index:545418498489416;">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" style="height:62px" v-show="checkMobile(route)" :data-color="getDataColor(route)" v-bind:key="route.ID_MENU" v-bind:class="{'dropdown':route.items.length > 0}" v-for="route in arrayMenu" >
              <router-link  tag="a" class="nav-link" v-bind:class="{'text-center':isMobile == false}" style="height:62px;border-radius: 6px;padding-left:20px;padding-right:20px;padding-bottom:4px" :to="route.ROUTE" v-if="! route.items.length > 0" v-bind:style="isCurrent(route)">
                <span style="width:100%">
                  <i   style="font-size:24px"  class="fa-fw"  v-bind:class="[route.ICONA]"></i><br v-if="isMobile == false"/><span style="width:20px;margin-right:10px" v-else> </span>{{route.DESCRIZIONE}}
                </span>
              </router-link>            
          </li>
        </ul>
      </div>
      <div>
        {{utente.EMAIL}}
      </div>
      <div class="topbar-divider d-none d-md-block " v-if="utente.ID_UTENTE > 0"></div>

      <ul class="navbar-nav ml-auto userinfo " style="" >
        <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle"  v-if="utente.ID_UTENTE > 0" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fa-stack">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fas fa-user fa-stack-1x fa-inverse"></i>
            </span>
            <!--
            <img class="img-profile rounded-circle" :src="'CORE/UPLOAD/AVATAR/'+ utente.URL_AVATAR" style="width:37px;height:37px">-->
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a class="dropdown-item" href="#" v-on:click="modificaProfilo()"  >
              <i class="fas fa-user fa-sm fa-fw mr-2 "></i>
              Profilo
            </a>
            <a class="dropdown-item" href="#" v-on:click="modificaPassword()">
              <i class="fas fa-cogs fa-sm fa-fw mr-2 "></i>
              Password
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#"   v-on:click="logout()" >
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 "></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column" style="margin-top:69px;padding-top:5px" >
          <div id="content" >
            <div class="container-fluid"  v-if="isMobile == false" id="kt_vue_content_parent" >
                <router-view/>
            </div>
            <div class="container-fluid" v-else id="kt_vue_content_parent" style="padding-left: 0.2rem ;padding-right: 0.2rem;">
                <router-view/>
            </div>
          </div>
      </div>

      <div class="modal fade" id="popUpConfirm"  role="dialog"  aria-modal="true" >
          <div class="modal-dialog"  >
              <div class="modal-content" >
                  <div class="modal-header modal-header-info">
                      <h5 class="modal-title">{{alertConfirm.TITLE}}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-2" style="margin-top:10px;font-size:40px">
                            <i class="fas " v-bind:class="alertConfirm.ICON"></i>
                        </div>
                        <div class="col-md-10 align-items-center" style="margin-top:10px">
                            <div v-html="alertConfirm.MESSAGE"></div>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-danger" v-on:click="alertConfirm.KOHandler()"><i class="fa fa-times fa-fw"></i>{{alertConfirm.KO}}</button>
                      <button type="button" class="btn btn-success" v-on:click="alertConfirm.OKHandler()"><i class="fa fa-check fa-fw"></i>{{alertConfirm.OK}}</button>
                  </div>
              </div>
          </div>
      </div>

      <div class="modal fade" id="popUpModificaPassword"  role="dialog"  aria-modal="true" >
          <div class="modal-dialog"  >
              <div class="modal-content" >
                  <div class="modal-header modal-header-info">
                      <h5 class="modal-title">Modifica Password</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                    <form  autocomplete="off">   
                      <div class="form-group" >
                          <label>Vecchia password</label><br/>
                          <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Vecchia password" type="password" v-model="passwordUtente.oldPassword">
                      </div> 
                      <div class="form-group" >
                          <label>Nuova password</label><br/>
                          <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Nuova password" type="password" v-model="passwordUtente.newPassword">
                      </div> 
                      <div class="form-group" >
                          <label>Conferma nuova password</label><br/>
                          <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Conferma nuova password" type="password" v-model="passwordUtente.confirmPassword">
                      </div>  
                    </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times fa-fw"></i>Annulla</button>
                      <button type="button" class="btn btn-success" v-on:click="doModificaPassword()"><i class="fa fa-check fa-fw"></i>Ok</button>
                  </div>
              </div>
          </div>
      </div>


    </div>    
  </div>
</template>


<script>

export default {
    name: 'App',
    data:function(){
        return {
          alertConfirm:{
            MESSAGE:"prova"
          },
          zindex:1100,
          title:'Dashboard',
          icon:'fa-home',
          passwordUtente:{},
          isAdmin : false,
          utente:{},
          arrayMenu: new Array(),
          arrayStagioni : new Array(),
          idStagioneCorrente:0,
          idMobile:false,
          previousRoute:"/"
        }
    },
    methods : {
      logout : function(){
        global.utils.ajax('core/logout',{}, (response) => {
          this.$root.utente = {};
          global.utils.deleteCookie("session_token");
          global.router.push("Login");
          this.arrayMenu = new Array();
        });
      },
      isCurrent : function(menu){
        var stile = {};
        stile['color'] = "#fff";
        stile['border-bottom'] = "2px solid #5a5c69";
        stile['font-weight'] = "bold";
        if (menu.ROUTE == this.currentRouteName || (this.mainMenuActive == 0 && menu.ROUTE == this.previousRoute)){
          stile['background'] = menu.COLORE;
          stile['border'] = "2px solid #fff" ;

         // stile['border-bottom'] = "2px solid " + menu.COLORE;
        }
        else{
          //stile['background'] = menu.COLORE + "20";
          stile['border'] = "2px solid rgba(0,0,0,0)" ;
        }
        return stile;
      },
      confirm : function(alertConfirm){
        this.alertConfirm = alertConfirm;
        var modal = global.$('#popUpConfirm');
        modal.modal('show');
      },
      setHeader : function(_title,_icon){
        this.title = _title;
        this.icon = _icon;
      },
      back : function(){
        global.router.go(-1);
      },
      modificaPassword : function(){
        var modal = global.$('#popUpModificaPassword');
        modal.modal('show');
      },
      modificaStagioneCorrente : function(){
        var modal = global.$('#popUpModificaStagione');
        modal.modal('show');
      },
      doModificaStagioneCorrente : function(){
        var modal = global.$('#popUpModificaStagione');
        modal.modal('hide');
        global.utils.ajax('as/stagionecorrente/update',{ID_STAGIONE:this.idStagioneCorrente}, (response,status) => {
          if (response.esito == 0){
            global.utils.alert.success("Operazione correttamente eseguita");
            this.startHandler();
          }
        });
      },
      modificaProfilo : function(){
        global.router.push("Profilo");
      },
      doModificaPassword : function(){
        //)7.HRK=L3M!xiyRq,vZ
        var modal = global.$('#popUpModificaPassword');
        modal.modal('hide');
        global.utils.ajax('core/utente/password/update',this.passwordUtente, (response,status) => {
          if (response.esito == 0){
            global.utils.alert.success("Operazione correttamente eseguita");
          }
        });
      },
      getDataColor : function(route){
        return route.DESCRIZIONE;
      },
      openPopUpLogin : function(){
          var modal = global.$('#popUpRecoveryLogin');
          modal.modal('show');
      },
      checkMobile : function(route){
        if (this.isMobile == false){
          return true;
        }
        else{
          if (route.ROUTE == '/Soci' || route.ROUTE == '/attivita' || route.ROUTE == '/abbonamenti'){
            return true;
          }
        }
        return false;
      },
      startHandler : function(){
        this.arrayMenu = new Array();
        global.router.push("Start");
        //1.VERIFICO ESISTENZA TOKEN
        console.log("*** AVVIO APPLICAZIONE ***");
        var token = global.utils.getCookie("session_token");
        //SE E' PRESENTE IL TOKEN, PROVIAMO A RECUPERARE L'Utente
        if (token != "" && token != undefined && token != "undefined"){
            global.utils.SESSION_TOKEN = token;
            global.utils.ajax('core/logged',{}, (response) => {
              if (response.esito == 0 && response.data.UTENTE.ID_UTENTE > 0){
                this.utente = response.data.UTENTE;
                for (var i = 0 ; i < this.utente.funzionalita.length ; i++){
                  if (this.utente.funzionalita[i].FK_ID_ITEM > 0){
                    this.arrayMenu.push(this.utente.funzionalita[i].menuItem);
                  }
                }
                global.router.push("Dashboard");
              }
              else{
                global.router.push("Login");
                this.arrayMenu = new Array();
              }
            });
        }
        else{
          this.arrayMenu = new Array();
          global.router.push("Login");
        }
      }
    },
    computed : {
      currentRouteName() {
          return this.$route.path;
      },
      mainMenuActive(){
        for (var i = 0 ; i < this.arrayMenu.length ; i++){
          if (this.arrayMenu[i].ROUTE == this.currentRouteName){
            return 1;
          }
        }
        return 0;
      }
    },
    created : function(){
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            this.isMobile = true;
        else
            this.isMobile = false;

        //global.utils.BASE_API = "http://localhost/dryland_2/CORE/SERVER/API/"; //process.env.VUE_APP_API_URL;
        global.utils.BASE_API = process.env.VUE_APP_API_URL;

        this.startHandler();
        setTimeout(() => {
            $('.modal').on('show.bs.modal',  (e) => {
                setTimeout(() => {
                    this.zindex++;
                    $('.modal-backdrop').last().css("z-index",this.zindex);
                    this.zindex++;
                    $('.modal.fade.show').last().css("z-index",this.zindex); 
                }, 200);
            });                    
        }, 400);
        /*
        global.utils.ajax2('core/login',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, (response) => {
          global.utils.SESSION_TOKEN = response.data.SESSION_TOKEN;
          global.utils.ajax2('core/logged',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, () => {
            global.utils.ajax2('core/home',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, () => {
              global.router.push("/soci"); 
            }); 
          });        
        });*/


        //this.startHandler();
    },
    
    watch:{
        $route (valore,valoreOld){
          console.log(valore.path,valoreOld.path);
          this.previousRoute = valoreOld.path;
          if (valore.path == "/Pagamento"){
            this.previousRoute = "/pagamenti";
          }
          $('#kt_vue_content_parent').hide().fadeIn(600);
          setTimeout(() => {
            global.$('form').submit(function(event){
                event.preventDefault();
                event.stopPropagation();
                return false;
            });
            $('.modal').on('show.bs.modal',  (e) => {
                setTimeout(() => {
                    this.zindex++;
                    $('.modal-backdrop').last().css("z-index",this.zindex);
                    this.zindex++;
                    $('.modal.fade.show').last().css("z-index",this.zindex); 

                    global.$('form').submit(function(event){
                        event.preventDefault();
                        event.stopPropagation();
                        return false;
                    });
                }, 200);
            });      
            global.utils.resizeContent();
          }, 300);
        }
    }
}
</script>


