<template>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-12" style="padding:0">
                            <div class="container">
                                <div class="p-5">
                                    <div class="text-center" style="margin-bottom:20px;width:100%">
                                       <!-- <img src="logo_scritta.png" style="width:100%" class="img-responsive d-none d-sm-block">-->
                                       <span class="h4">Deskalo</span>
                                    </div>
                                    <div class="user">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control form-control-user"  v-model="utente.USERNAME"  >
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control form-control-user"  v-on:keyup.enter="login()"  v-model="utente.PASSWORD" >
                                        </div>
                                        <button v-on:click="login()" class="btn btn-success btn-lg btn-user btn-block"><i class="fal fa-sign-in-alt fa-fw"></i> Login</button>
                                        <!--
                                        <hr>
                                        <button v-on:click="registrazione()" class="btn btn-warning btn-user btn-lg btn-block"><i class="fal fa-pencil-alt fa-fw"></i> Registrati</button>
                                        -->
                                    </div>
                                </div>          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>


</template>

<script>



export default {
    name: 'Login',
    data:function(){
        return {
            utente:{
                //email:'utente@utente.it',
                //password:')7.HRK=L3M!xiyRq,vZ'
            }
        }
    },
    methods : {
        login(){
            global.utils.ajax('core/login',{USERNAME:this.utente.USERNAME,PASSWORD:this.utente.PASSWORD}, (response) => {
                if (response.esito == 0){
                    global.utils.setCookie("session_token",response.data.SESSION_TOKEN);
                    global.utils.TOKEN = response.token;
                    this.$root.startHandler();
                }
                else{
                    global.utils.alert.warning(response.responseText);
                }
            });
        }
    },
    created : function(){
        global.utils.setHeader('fa-sign-in','Login');
    }
}
</script>